import Vue from "vue";
import Router from "vue-router";
import HomePage from "../pages/HomePage.vue";
import Login from "../components/Login.vue";
import Redirect from "../components/Redirect.vue";
import Registration from "../components/Registration.vue";
import Sources from "../components/Sources.vue";
import Devices from "../components/Devices.vue";
import ErrorPage from "../pages/ErrorPage.vue";
import PersonalPage from "../pages/PersonalPage.vue";
import AddSource from "../components/AddSource.vue";
import PlayerView from "../components/PlayerView.vue";
import ProfilesList from "../components/ProfilesList.vue";
import Service from "../components/Service.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      component: HomePage,
      name: "home",
      meta: {
        title: "Home",
      },
      children: [
        {
          path: "/",
          component: Login,
          name: "login",
          meta: {
            title: "Login page",
            isRoot: true,
          },
        },
        {
          path: "registration",
          component: Registration,
          name: "registration",
          meta: {
            title: "Registration page",
            isRoot: true,
          },
        },
        {
          path: "redirect",
          component: Redirect,
          name: "redirect",
          meta: {
            title: "Redirect page",
            isRoot: true,
          },
        },
      ],
    },
    {
      path: "/personal",
      component: PersonalPage,
      meta: {
        title: "Personal",
        isPersonal: true,
      },
      children: [
        {
          path: "/personal/player",
          component: PlayerView,
          name: "player",
          meta: {
            title: "Player",
            isPersonal: true,
          },
        },
        {
          path: "/personal/add",
          component: AddSource,
          name: "add_source",
          meta: {
            title: "Add source",
            isPersonal: true,
          },
        },
        {
          path: "/personal/sources",
          component: Sources,
          name: "sources",
          meta: {
            title: "Sources",
            isPersonal: true,
          },
        },
        {
          path: "/personal/devices",
          component: Devices,
          name: "devices",
          meta: {
            title: "Devices",
            isPersonal: true,
          },
        },
        {
          path: "/personal/profiles",
          component: ProfilesList,
          name: "profiles",
          meta: {
            title: "ProfilesList",
            isPersonal: true,
          },
        },
        {
          path: "/personal/service",
          component: Service,
          name: "service",
          meta: {
            title: "Service",
            isPersonal: true,
          },
        },
      ],
    },

    {
      path: "*",
      component: ErrorPage,
    },
  ],
  mode: "history",
  scrollBehavior,
});

const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition;
  } else {
    const position = {};

    // scroll to anchor by returning the selector
    if (to.hash) {
      position.selector = to.hash;

      // specify offset of the element
      if (to.hash === "#anchor2") {
        position.offset = { y: 100 };
      }

      // bypass #1number check
      if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
        return position;
      }

      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      return false;
    }

    return new Promise((resolve) => {
      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some((m) => m.meta.scrollToTop)) {
        // coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.x = 0;
        position.y = 0;
      }

      // wait for the out transition to complete (if necessary)
      this.app.$root.$once("triggerScroll", () => {
        // if the resolved position is falsy or an empty object,
        // will retain current scroll position.
        resolve(position);
      });
    });
  }
};
