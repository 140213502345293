import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
import snackbar from "./snackbar";
import drawer from "./drawer";
import auth from "./auth";
import device from "./device";
import playlist from "./playlist";
import profile from "./profile";
import service from "./service";

var VueCookie = require("vue-cookie");

Vue.use(Vuex);
Vue.use(VueCookie);

export default new Vuex.Store({
  modules: {
    app,
    snackbar,
    auth,
    drawer,
    device,
    playlist,
    profile,
    service,
  },
});
