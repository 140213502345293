export default {
  pageText: "{0} en",
  noDataText: "No data",
  lang: {
    ru: "Russian",
    en: "English",
    es: "Spanish",
  },
  addPlaylist: {
    name: "Title",
    urlAddress: "URL address",
    forDevices: "Available on devices",
    save: "Save",
    incorrectUrl: "Invalid URL",
    onlyM3UAllowed: "Only M3U and M3U8 playlists can be downloaded",
    selectAll: "Select all",
    playlistAllowOnAll: "The playlist will be available on all your devices",
    playlistAllowOnSelected: "The playlist will be available on the selected devices",
    selectOneOrMore: "Select one or more devices",
    allowOnDevices: "A playlist will be available on the selected devices"
  },
  drawer: {
    player: "Player",
    addPlaylist: "Add a playlist",
    playlists: "Playlists",
    devices: "Devices",
    profiles: "Profiles",
    exit: "Exit"
  },
  devices: {
    deleteButton: "Remove",
    deleteDialog: {
      title: "Removal",
      wantDelete: "You want to delete the device: {0}",
      cancel: "Cancel",
      delete: "Remove"
    }
  },
  loginForm: {
    title: "Authorization",
    emailField: "Email",
    passwordField: "Password",
    loginButton: "Enter",
    registrationButton: "Registration",
  },
  playlist: {
    channels: "{0} channels",
    allowedOn: "Available on {0} devices",
    details: "More detailed",
    edit: "Change",
    deleteDialog: {
      title: "Remove",
      wantDelete: "You want to delete a playlist: {0}",
      cancel: "Cancel",
      delete: "Remove"
    },
    editForm: {
      title: "Editing a playlist",
      nameField: "Title",
      saveButton: "Save",
      cancelButton: "Cancel",
    },
  },
  profile: {
    saveSystemDialogSave: "Update systems",
    saveDialog: "The list of available channels has been changed. Don't forget to save your changes.",
    saveDialogSave: "Save",
    saveDialogCancel: "Cancel",
    currentPlaylist: "Playlist",
    groupFilter: "Filter by groups",
    currentPage: "Page {0} of {1}",
    channels: "Channels",
    deleteDialog: {
      title: "Removal",
      wantDelete: "You want to delete the profile: {0}",
      cancel: "Cancel",
      delete: "Remove"
    },
    editForm: {
      title: "Editing a profile",
      nameField: "Title",
      saveButton: "Save",
      restoreButton: "Recover",
      deleteButton: "Remove",
      cancelButton: "Cancel",
      pinFieldHint: "Пинкод профиля",
      pinFieldDesc: "Введите 4 цифры или оставьте поле пустым",
    },
  },
  registrationForm: {
    title: "Registration",
    emailField: "Email",
    nickField: "Nickname",
    loginButton: "Enter",
    registrationButton: "Registration",
  },
  detail: {
    privacyPolicy: "Privacy policy",
    terms: "Use conditions",
  },
  formMessages: {
    isRequired: "This field must be filled in",
    mustBeLess: "The field must contain no more than {0} characters",
    onlyDigits: "Поле может содержать только цифры",
    mustBeEqual: "Поле должно содержать ровно {0} символа",
    incorrectEmail: "Enter the correct email address",
  },
  emptyView: {
    titleDevice: "The list of devices is empty",
    descriptionDevice: "A new device is added to this list automatically when you install the app on your device to play videos. After the first successful authorization on the device under your account.",
    titleSource: "The playlist list is empty",
    descriptionSource: "Working with the app assumes that you already have a link to the M3U or m3u8 playlist. Add a link to your existing playlist.",
    addPlaylist: "Go to add a link to the playlist",
    routeButtonAdd: "Add",
    downloadDescription: "The app is available on Google or Apple devices",
    titleProfile: "The list of profiles is not available yet",
    descriptionProfile: "Working with the app assumes that you already have a link to the M3U or m3u8 playlist. Add a link to your existing playlist.",
  },
  externalView: {
    title: "Where can I get a playlist?",
    description: "If you don't have a playlist to watch yet, I can recommend a great service of the same name. Sign up, get a link to a playlist with thousands of TV channels and enjoy excellent video and audio quality.",
    addition: "Enjoy your viewing!",
    buttonLabel: "Go over",
  },
  playerView: {
    title: "iLook ott player",
    description: "iLook ott Player is a simple and convenient player for watching M3U8 playlists available on several popular platforms. You can easily find the app in the App Store and Google Play app stores. The web version of the application has recently been launched.",
    description_important: "important!",
    description_attansion: "This application does not contain TV channels and serves exclusively for playing playlists that you already have.",
    labelToSite: "Watch on the website"
  },
  successMessages: {
    profileRestored: "Profile restored",
    successLogin: "Welcome!",
    successRegistration: "Your registration data has been sent to the specified email",
    playlistAdded: "Playlist added successfully",
    playlistSaved: "Playlist changes saved",
    playlistDeleted: "Playlist deleted",
    deviceUpdated: "Device information has been updated",
    deviceDeleted: "The device has been deleted",
    profileAdded: "Profile successfully added",
    profilesSaved: "Changes in profiles are saved",
    profileSaved: "Profile saved"
  },
  failMessages: {
    failProfileAdd: "Error when adding a profile",
    failProfileUpdate: "Error updating profile",
    failPlaylistUpdate: "Error updating playlist",
  }
};
