import router from "../router";
import axios from "axios";
import apiUtil from "../util/api_util";
//https://tvlider.net/welcome/signup/202ac7a4182fa7bd
export default {
  state: {
    userNick: localStorage.getItem("userNick"),
    userEmail: localStorage.getItem("email"),
    appName: "iLook2",
    defWord: "sistre",
    isAllowExternalLink: false,
    externalLink: "https://melord.net/welcome/signup/202ac7a4182fa7bd",
    serverUrl: "https://file.ilook.su/",
    supportedLocales: ["ru", "en", "es"],
    recaptchaKey: "6LeBnsAZAAAAAAi5ykC2hRHypS3JPw_ZkoGYGUPF",
  },
  getters: {
    // isAuthorized() {
    //   return localStorage.token != null && localStorage.token != "null"
    //   // return typeof localStorage.token === 'string' || localStorage.token instanceof String
    //   // console.log("isAuthorized :>> ", !!localStorage.token);
    //   // return !!localStorage.token;
    // },
    getDefWord(state) {
      return state.defWord;
    },
    getServerUrl(state) {
      return state.serverUrl;
    },
    getAppName(state) {
      return state.appName;
    },
    getAppNameAndPrefix(state) {
      return state.appName + "/api";
    },
    getLocales(state) {
      return state.supportedLocales;
    },
    getRecaptchaKey(state) {
      return state.recaptchaKey;
    },
    isAdmin() {
      return localStorage.getItem("email") == "goldenplan@yandex.ru" ||
        localStorage.getItem("email") == "goldenplan@yandex.kz";
    },
  },

  mutations: {
    updateUserId(state, payload) {
      console.log("set userId :>> ", payload);
      localStorage.userId = payload;
    },
    updateUserNick(state, payload) {
      console.log("set userNick :>> ", payload);
      state.userNick = payload;
      localStorage.userNick = payload;
    },
    updateUserEmail(state, payload) {
      console.log("set userEmail :>> ", payload);
      state.userEmail = payload;
      localStorage.email = payload;
    },
    updateUserFile(state, payload) {
      console.log("set userFile :>> ", payload);
      state.userFile = payload;
      localStorage.userFile = payload;
    },
    setAllowExternalLink(state, payload) {
      console.log("setAllowExternalLink", payload);
      state.isAllowExternalLink = payload
    },
    updateToken(state, response) {
      console.log("response-token :>>>> ", response);
      if (response.headers.token) {
        localStorage.token = response.headers.token;
      } else {
        localStorage.token = null;
      }
    },
    showLoginPageIfNeed(state, error) {
      if (error.response.status === 401) {
        router.push({ name: "login" });
      }
      if (error.response.status === 403) {
        router.push({ name: "login" });
      }
    }
  },
  actions: {
    getVersionInfo(context, payload) {
      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);
      console.log("payload :>> ", payload);

      let config = {
        headers,
      };

      axios
        .get(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/app/`,
          { params: { version: "2.0", os: "site" } },
          config
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.object) {
            console.log("object :>> ", data.object);
            context.commit("setAllowExternalLink", !data.object.onReview);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
