<template>
  <v-navigation-drawer
    class="pl-3"
    :style="getOffset()"
    v-model="$store.state.drawer.isShowing"
    :absolute="true"
    :prominent="$store.state.drawer.isSmallScreen"
    :permanent="!$store.state.drawer.isSmallScreen"
  >
    <v-list class="">
      <v-list-item class="pl-4">
        <v-list-item-avatar class="ma-0">
          <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title
            class="title"
            v-text="userNick"
          ></v-list-item-title>
          <v-list-item-subtitle
            v-text="userEmail"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item link :to="{ name: `player` }">
        <v-list-item-icon>
          <v-icon>mdi-play</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{
          this.$vuetify.lang.translator("drawer.player")
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: `add_source` }">
        <v-list-item-icon>
          <v-icon>mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{
          this.$vuetify.lang.translator("drawer.addPlaylist")
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: `sources` }">
        <v-list-item-icon>
          <v-icon>mdi-playlist-play</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{
          this.$vuetify.lang.translator("drawer.playlists")
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: `devices` }">
        <v-list-item-icon>
          <v-icon>mdi-television</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{
          this.$vuetify.lang.translator("drawer.devices")
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: `profiles` }">
        <v-list-item-icon>
          <v-icon>mdi-human</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{
          this.$vuetify.lang.translator("drawer.profiles")
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="this.isAdmin" link :to="{ name: `service` }">
        <v-list-item-icon>
          <v-icon>mdi-history</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Service</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="$store.dispatch('tryLogout')">
        <v-list-item-icon>
          <v-icon>mdi-exit-run</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{
          this.$vuetify.lang.translator("drawer.exit")
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",
  data() {
    return {};
  },
  computed: {
    userNick(){
      return this.$store.state.app.userNick
    },
    userEmail(){
      return this.$store.state.app.userEmail
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
  },
  methods: {
    getOffset() {
      return `padding-top: ${this.$store.state.drawer.scrollOffset + 60}px`;
    },
  },
  created() {
    this.$store.dispatch("getInfo");
  },
};
</script>