<template>
<v-container v-if="isEmpty">
    <empty-view 
    :title="this.$vuetify.lang.translator('emptyView.titleSource')" 
    :description="this.$vuetify.lang.translator('emptyView.descriptionSource')" 
    :isShowAppLink="false">

    </empty-view>
  </v-container>
  <v-container v-else>
    <v-col v-for="(item, i) in $store.state.playlist.list" :key="i">
      <v-card class="mx-auto content" max-width="344" outlined>
        <v-btn icon class="div-1a ma-1" @click="showDeleteDialog(item)">
          <v-icon class="ma-2">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="pb-1">
          <v-icon large left> mdi-motion-play-outline </v-icon>
          <span class="title font-weight-light">{{ item.name }}</span>
        </v-card-title>
        <v-card-subtitle class="mt-0 pb-1">
          <span>{{$vuetify.lang.translator('playlist.channels', item.count)}}</span>
        </v-card-subtitle>

        <v-card-actions class="ma-0 pa-0 pl-2">
          <v-btn icon @click="item.show = !item.show">
            <v-icon>{{
              item.show ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
          <v-list-item disabled class="ma-0 pl-0">
            <v-list-item-content>
              <v-list-item-title>
                {{$vuetify.lang.translator('playlist.allowedOn', item.devices.length)}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-spacer></v-spacer>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="item.show">
            <v-divider></v-divider>

            <v-card-text>
              {{ item.deviceDescription }}
            </v-card-text>
          </div>
        </v-expand-transition>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="lighten-2" text @click="showEditDetail(item)">
            {{$vuetify.lang.translator('playlist.details')}}
          </v-btn>
          <v-btn color="orange lighten-2" text @click="showEditDialog(item)">
            {{$vuetify.lang.translator('playlist.edit')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <playlist-edit-dialog
      v-if="$store.state.playlist.isShowingEditDialog"
    ></playlist-edit-dialog>
    <playlist-delete-dialog
      v-if="$store.state.playlist.isShowingDeleteDialog"
    ></playlist-delete-dialog>
  </v-container>
</template>

<script>
import PlaylistEditDialog from "@/components/PlaylistEditDialog";
import PlaylistDeleteDialog from "@/components/PlaylistDeleteDialog";
import EmptyView from "@/components/EmptyView";

export default {
  name: "Sources",
  components: {
    playlistEditDialog: PlaylistEditDialog,
    playlistDeleteDialog: PlaylistDeleteDialog,
    emptyView: EmptyView
  },
  data() {
    return {
      currentDevice: null,
    };
  },
  computed: {
    isEmpty(){
      return !this.$store.state.playlist.list || this.$store.state.playlist.list.length == 0
    }
  },
  methods: {
    showEditDetail(playlist) {
      this.$store.dispatch("getPlaylistInfo", playlist);
    },
    showEditDialog(playlist) {
      this.$store.commit("setPlaylistForEdit", playlist);
    },
    cancelEditDialog() {
      this.$store.commit("setPlaylistForEdit", null);
    },
    showDeleteDialog(playlist) {
      this.$store.commit("setPlaylistForDelete", playlist);
    },
    cancelDeleteDialog() {
      this.$store.commit("setPlaylistForDelete", null);
    },
  },
  created() {
    this.$store.dispatch("getPlaylists");
    this.$store.dispatch("getDevices");
  },
};
</script>

<style scoped>
.content {
  position: relative;
}
.div-1a {
  position: absolute;
  top: 0;
  right: 0;
  width: 50;
}
</style>

