<template>
  <v-container> 
      <div>
    <v-alert
      text
      color="success"
      type="info"
    >
      <h3 class="headline">
        {{this.$vuetify.lang.translator('externalView.title')}}
      </h3>
      <div>{{this.$vuetify.lang.translator('externalView.description')}}</div>

      <v-divider
        class="my-4 success"
        style="opacity: 0.22"
      ></v-divider>

      <v-row
        align="center"
        no-gutters
      >
        <v-col class="grow">
          {{this.$vuetify.lang.translator('externalView.addition')}}
        </v-col>
        <v-spacer></v-spacer>
        <v-row class="shrink">
            <v-btn
                  :href="this.$store.state.app.externalLink"
                  target="_blank"
                  class="ma-2 mr-4"
                  color="success"
                  outlined
                >
                  {{this.$vuetify.lang.translator('externalView.buttonLabel')}}
                  
            </v-btn>
        </v-row>
            
      </v-row >
      
            
    </v-alert>
  </div>
  </v-container>
</template>

<script>

export default {
    name: "ExternalView",
    components: {},
  data() {
    return {
    };
  },
};
</script>